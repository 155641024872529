import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
// import Logo from '../assets/images/blue_logo.png';
// import Logo from '../assets/images/logo_white.png';
import Logo from '../assets/images/logo_crop.png';
import AI_TEXT from '../assets/images/ai_text.png';
import AI from '../assets/images/ai_button.png';
import LongText from '../assets/images/long_text.png';
import ThreeText from '../assets/images/three_text.png';
import Money1 from '../assets/images/money_1.png';
import Money2 from '../assets/images/money_2.png';
import Pencil from '../assets/images/pencil.png';
import Phone from '../assets/images/saup_phone.png';
import { motion } from "framer-motion"


function HeroSection() {
  return (
    <div className='hero-container'>

      {/* <video src='/videos/sky-1.mp4' autoPlay loop muted /> */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5 }}
        style={{ marginTop: "130px", display: 'flex', alignItems: 'center', justifyContent: 'center', width: "100%" }}>
        {/* <img className='banner-logo-detail' src={AI_TEXT} alt="Logo" /> */}
        <div className='main-text-container'>
          <p>기업맞춤형 <div className='highlight-text'>정책자금</div></p>
          <p>무료 <div className='highlight-text'>AI</div> 진단받기</p>
        </div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.7 }}
        style={{ marginTop: "25px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <a href='/slide' style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 10,
          color: 'black',
          textDecoration: 'none',
        }}>
          <p className='saup-text' style={{ display: 'flex', alignItems: 'center' }}>
            <motion.span
              animate={{ x: [-10, 0] }}  // 왼쪽에서 원래 자리로 이동
              transition={{ duration: 1, repeat: Infinity, repeatType: "mirror" }} // 반복
              style={{ marginRight: "5px" }}
            >
              👉
            </motion.span>
            <span className='highlight-text' style={{ margin: "0 5px" }}>사업자등록번호</span>
            하나로
            <span className='highlight-text' style={{ margin: "0 5px" }}>정책자금</span>
            조회
            <motion.span
              animate={{ x: [10, 0] }}  // 오른쪽에서 원래 자리로 이동
              transition={{ duration: 1, repeat: Infinity, repeatType: "mirror" }}  // 반복
              style={{ marginLeft: "5px" }}
            >
              👈
            </motion.span>
          </p>
        </a>
      </motion.div>
      {/* <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.7 }}
        style={{ marginTop: "25px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <a href='/slide' style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 10,
          color: 'black',
          textDecoration: 'none',
        }}>
          <motion.p
            className='saup-text'
            animate={{ scale: [1, 1.1, 1] }}
            transition={{ duration: 4.5, repeat: Infinity, repeatType: "reverse" }}
          >
            <motion.span
              animate={{ x: [-20, 0] }}  // 왼쪽에서 원래 자리로 이동
              transition={{ duration: 1, repeat: Infinity, repeatType: "mirror" }} // 반복
            >
              👉
            </motion.span>
            <motion.span
              className='highlight-text'
              animate={{ scale: [1, 1.1, 1] }}
              transition={{ duration: 4.5, repeat: Infinity, repeatType: "reverse" }}>
              사업자등록번호
            </motion.span>
            하나로
            <motion.span
              className='highlight-text'
              animate={{ scale: [1, 1.1, 1] }}
              transition={{ duration: 4.5, repeat: Infinity, repeatType: "reverse" }}>
              정책자금
            </motion.span>
            <motion.span
              animate={{ x: [20, 0] }}  // 오른쪽에서 원래 자리로 이동
              transition={{ duration: 1, repeat: Infinity, repeatType: "mirror" }}  // 반복
            >
              👈
            </motion.span>
          </motion.p>
        </a>
      </motion.div> */}

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.8 }}
        style={{ marginTop: "15px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <a href='/slide' style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 10,
          color: 'white',
        }}>
          <img className='banner-logo-detail' src={AI} alt="Logo" />
        </a>
      </motion.div>

      <div class="s1_arrow">
        <div class="scroll-arrow"></div>
        <div class="scroll-arrow"></div>
      </div>


      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.1 }}
        style={{
          marginTop: 30,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          lineHeight: 1.5,
        }}>
        {/* <p className='banner_text'>EM파트너스는 가장 합리적이게 자금이 지원되도록</p>
        <p className='banner_text'>정책자금을 빠르고 신속하게 찾아드립니다.</p> */}
        {/* <img className='banner-logo-text' src={LongText} alt="Logo" /> */}
        <div className='sub-text-container'>
          <p><div className='highlight-text'>EM파트너스</div>는 가장 합리적이게 자금이 지원되도록</p>
          <p><div className='highlight-text'>정책자금</div>을 빠르고 신속하게 찾아드립니다.</p>
        </div>
      </motion.div>

      {/* <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.3 }}
      >
        <img className='' src={Pencil} alt="Logo" />
      </motion.div> */}


      {/* <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.3 }}
        style={{
          position: 'absolute',
          width: "100%",
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          paddingBottom: '100px',
          paddingLeft: '100px',
          paddingRight: '100px',
          // backgroundColor: "rgba(255,255,255)",
        }}
      >
        <img className='money_2_icon' src={Money2} alt="Logo" />
        <img className='money_1_icon' src={Money1} alt="Logo" />
      </motion.div> */}

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.3 }}
        style={{
          display: 'flex',
          alignItems: 'center',
          // backgroundColor: "rgba(255,255,255)",
        }}
      >
        <img className='phone_icon' src={Phone} alt="Logo" />
      </motion.div>


      {/* <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.5 }}
        className='price__section__container'>
        <div className='price__section'>
          <p className='price__section__title'>정책자금 예산</p>
          <p>18<b className="different__size">조</b> 7<b className="different__size">천</b></p>
        </div>
        <div className='price__section'>
          <p className='price__section__title'>정부지원금</p>
          <p>5<b className="different__size">조</b></p>
        </div>
        <div className='price__section'>
          <p className='price__section__title'>정책자금 종류</p>
          <p>300<b className="different__size">여개</b></p>
        </div>
      </motion.div> */}

      {/* <div className='hero-btns'>

        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
        >
          문의하기
        </Button>
        <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={console.log('hey')}
        >
          카카오톡 <i className='far fa-play-circle' />
        </Button>
      </div> */}
    </div>
  );
}

export default HeroSection;