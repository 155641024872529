import React from 'react';
import '../App.css';
import './VideoScroll.css';
import sample from '../assets/videos/sky-1.mp4';

import DachulText from '../assets/images/dachul_between.png';

import LongSanghwan from '../assets/images/long_sanghwan.png';
import LowEja from '../assets/images/low_eja.png';
import HighHando from '../assets/images/high_hando.png';

import Money2 from '../assets/images/money_2.png';
import Money1 from '../assets/images/money_1.png';
import Chart from '../assets/images/business_chart.png';
import Bank from '../assets/images/3d_bank.png';
import Date from '../assets/images/date_from.png';

import Chart_Text from '../assets/images/chart_text.png';
import Bank_Text from '../assets/images/bank_text.png';
import Date_Text from '../assets/images/date_text.png';

import { motion } from 'framer-motion';

function VideoScroll() {

  return (
    <div className='video-container'>
      <div className='dachul'>
        <img className='dachul__text__img' src={DachulText} alt="Logo" />
      </div>

      <div className='three__container'>

        <motion.div
          className='normarl__container'
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false }}
          transition={{
            ease: "easeInOut",
            duration: 0.5,
            y: { duration: 0.5 },
          }}
        >
          <img className='normal__text__detail' src={Chart_Text} alt="Logo" />
          <img className='normal__logo__detail_date' src={Money1} alt="Logo" />
        </motion.div>
        {/* <div className='normarl__container'>
          <img className='normal__text__detail' src={Chart_Text} alt="Logo" />
          <img className='normal__logo__detail' src={Chart} alt="Logo" />
        </div> */}

        <motion.div
          className='normarl__container'
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false }}
          transition={{
            ease: "easeInOut",
            duration: 0.5,
            y: { duration: 0.5 },
          }}
        >
          <img className='normal__text__detail' src={Bank_Text} alt="Logo" />
          <img className='normal__logo__detail' src={Bank} alt="Logo" />
        </motion.div>

        <motion.div
          className='normarl__container'
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false }}
          transition={{
            ease: "easeInOut",
            duration: 0.5,
            y: { duration: 0.5 },
          }}
        >          <img className='normal__text__detail' src={Date_Text} alt="Logo" />
          <img className='normal__logo__detail_date' src={Date} alt="Logo" />
        </motion.div>
      </div>

      {/* <video src='/videos/sky-2.mp4'/> */}
      {/* <source src="../assets/videos/sky-1.mp4" type='video/mp4' /> */}
    </div>
  );
}

export default VideoScroll;