import React from 'react'
import './Channel.css';
// import Kakao from '../assets/svg/kakao_icon.svg';
import { ReactComponent as Kakao } from '../assets/svg/kakao_icon.svg';
import { ReactComponent as Insta } from '../assets/svg/instagram_icon.svg';
import { Link } from 'react-router-dom';
// import FastConsult from './FastConsult'
import { motion } from "framer-motion"
import Instagram from '../assets/images/instagram.png';
import Kakaotalk from '../assets/images/kakao-talk.png';
import SangdamMini from '../assets/images/sangdam_mini.png';
import NaverBlogIcon from '../assets/images/NaverBlog.png';
import AI from '../assets/images/AI.png';
import FAST from '../assets/images/fast.png';


function Channel({ openFastModal }) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.5 }}>
      <div className='widget'>
        <a href='tel:1688-7510' className='img-icon-container-top' target='_blank'>
          {/* <a href='https://forms.gle/zta7K4Zi7D4Qjbwm6' className='img-icon-container-top' target='_blank'> */}
          <img className='img-icon' src={SangdamMini} alt="KaKao" />
        </a>

        <a onClick={openFastModal} className='img-icon-container'>
          {/* <i class="far fa-file-alt" style={{ fontSize: "30px" }}></i>
          <div>
            <p>상담신청</p>
            <p>하러가기</p>
          </div> */}
          <img className='img-icon' src={FAST} alt="Insta" />
        </a>

        <a href='/slide' className='img-icon-container'>
          {/* <i class="far fa-file-alt" style={{ fontSize: "30px" }}></i>
          <div>
            <p>상담신청</p>
            <p>하러가기</p>
          </div> */}
          <img className='img-icon' src={AI} alt="Insta" />
        </a>

        <a href='https://www.instagram.com/em_partner_s/?igsh=M2Z0ZXoyM3BhZ3lk' className='img-icon-container' target='_blank'>
          {/* <i class="far fa-file-alt" style={{ fontSize: "30px" }}></i>
          <div>
            <p>상담신청</p>
            <p>하러가기</p>
          </div> */}
          <img className='img-icon' src={Instagram} alt="Insta" />
        </a>

        <a href='http://pf.kakao.com/_xokkxkG' className='img-icon-container' target='_blank'>
          {/* <i class="far fa-file-alt" style={{ fontSize: "30px" }}></i>
          <div>
            <p>상담신청</p>
            <p>하러가기</p>
          </div> */}
          <img className='img-icon' src={Kakaotalk} alt="KaKao" />
        </a>

        <a href='https://blog.naver.com/empareners' className='img-icon-container-bottom' target='_blank'>
          {/* <i class="far fa-file-alt" style={{ fontSize: "30px" }}></i>
          <div>
            <p>상담신청</p>
            <p>하러가기</p>
          </div> */}
          <img className='img-icon' src={NaverBlogIcon} alt="Blog" />
        </a>
      </div>
    </motion.div>
  )
}

export default Channel
