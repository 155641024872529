import React from 'react';
import './Process.css';

import Slice1 from '../assets/images/Slide (1).png';
import Slice2 from '../assets/images/Slide (2).png';
import Slice3 from '../assets/images/Slide (3).png';
import Slice4 from '../assets/images/Slide (4).png';
import Slice5 from '../assets/images/Slide (5).png';
import Slice6 from '../assets/images/Slide (6).png';
import Slice7 from '../assets/images/Slide (7).png';
import Slice8 from '../assets/images/Slide (8).png';


import { motion } from "framer-motion"

function Process() {

  // const isPc = useMediaQuery({
  //   query: "(min-width:1024px)"
  // });
  // const isTablet = useMediaQuery({
  //   query: "(min-width:768px) and (max-width:1023px)"
  // });
  // const isMobile = useMediaQuery({
  //   query: "(max-width:767px)"
  // });


  return (
    <div className='process'>
      <div className='process_text_container'>
        <p style={{
          color: 'white',
          whiteSpace: 'normal',
          marginBottom: '5px',
        }}>EMPARTNERS</p>
        <p className='process-title'>1대1 상담신청프로세스</p>
      </div>

      <div className='grid-container'>
        <img className='process_img' src={Slice1} alt="Slide 1" />
        <img className='process_img' src={Slice2} alt="Slide 2" />
        <img className='process_img' src={Slice3} alt="Slide 3" />
        <img className='process_img' src={Slice4} alt="Slide 4" />
        <img className='process_img' src={Slice5} alt="Slide 5" />
        <img className='process_img' src={Slice6} alt="Slide 6" />
        <img className='process_img' src={Slice7} alt="Slide 7" />
        <img className='process_img' src={Slice8} alt="Slide 8" />
      </div>


    </div >
  );
}

export default Process;