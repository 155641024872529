import React from 'react';
import '../App.css';
import './CardSlide.css';

import SuccessReviewText from '../assets/images/success_review_text.png';

import slidereview1 from '../assets/images/slidereview (1).png';
import slidereview2 from '../assets/images/slidereview (2).png';
import slidereview3 from '../assets/images/slidereview (3).png';
import slidereview4 from '../assets/images/slidereview (4).png';
import slidereview5 from '../assets/images/slidereview (5).png';

function CardSlide() {
  return (
    <div className="card-container">
      <img className='success-review-text' src={SuccessReviewText} alt="Success Review Text" />
      <div className="logos">
        <div className="logos-slide">
          <img className='hi' src={slidereview1} alt="Slide 1" />
          <img className='hi' src={slidereview2} alt="Slide 2" />
          <img className='hi' src={slidereview3} alt="Slide 3" />
          <img className='hi' src={slidereview4} alt="Slide 4" />
          <img className='hi' src={slidereview5} alt="Slide 5" />
        </div>
        <div className="logos-slide">
          <img className='hi' src={slidereview1} alt="Slide 1" />
          <img className='hi' src={slidereview2} alt="Slide 2" />
          <img className='hi' src={slidereview3} alt="Slide 3" />
          <img className='hi' src={slidereview4} alt="Slide 4" />
          <img className='hi' src={slidereview5} alt="Slide 5" />
        </div>
        <div className="logos-slide">
          <img className='hi' src={slidereview1} alt="Slide 1" />
          <img className='hi' src={slidereview2} alt="Slide 2" />
          <img className='hi' src={slidereview3} alt="Slide 3" />
          <img className='hi' src={slidereview4} alt="Slide 4" />
          <img className='hi' src={slidereview5} alt="Slide 5" />
        </div>
        <div className="logos-slide">
          <img className='hi' src={slidereview1} alt="Slide 1" />
          <img className='hi' src={slidereview2} alt="Slide 2" />
          <img className='hi' src={slidereview3} alt="Slide 3" />
          <img className='hi' src={slidereview4} alt="Slide 4" />
          <img className='hi' src={slidereview5} alt="Slide 5" />
        </div>
        <div className="logos-slide">
          <img className='hi' src={slidereview1} alt="Slide 1" />
          <img className='hi' src={slidereview2} alt="Slide 2" />
          <img className='hi' src={slidereview3} alt="Slide 3" />
          <img className='hi' src={slidereview4} alt="Slide 4" />
          <img className='hi' src={slidereview5} alt="Slide 5" />
        </div>
        <div className="logos-slide">
          <img className='hi' src={slidereview1} alt="Slide 1" />
          <img className='hi' src={slidereview2} alt="Slide 2" />
          <img className='hi' src={slidereview3} alt="Slide 3" />
          <img className='hi' src={slidereview4} alt="Slide 4" />
          <img className='hi' src={slidereview5} alt="Slide 5" />
        </div>
        <div className="logos-slide">
          <img className='hi' src={slidereview1} alt="Slide 1" />
          <img className='hi' src={slidereview2} alt="Slide 2" />
          <img className='hi' src={slidereview3} alt="Slide 3" />
          <img className='hi' src={slidereview4} alt="Slide 4" />
          <img className='hi' src={slidereview5} alt="Slide 5" />
        </div>
        <div className="logos-slide">
          <img className='hi' src={slidereview1} alt="Slide 1" />
          <img className='hi' src={slidereview2} alt="Slide 2" />
          <img className='hi' src={slidereview3} alt="Slide 3" />
          <img className='hi' src={slidereview4} alt="Slide 4" />
          <img className='hi' src={slidereview5} alt="Slide 5" />
        </div>
        <div className="logos-slide">
          <img className='hi' src={slidereview1} alt="Slide 1" />
          <img className='hi' src={slidereview2} alt="Slide 2" />
          <img className='hi' src={slidereview3} alt="Slide 3" />
          <img className='hi' src={slidereview4} alt="Slide 4" />
          <img className='hi' src={slidereview5} alt="Slide 5" />
        </div>
        <div className="logos-slide">
          <img className='hi' src={slidereview1} alt="Slide 1" />
          <img className='hi' src={slidereview2} alt="Slide 2" />
          <img className='hi' src={slidereview3} alt="Slide 3" />
          <img className='hi' src={slidereview4} alt="Slide 4" />
          <img className='hi' src={slidereview5} alt="Slide 5" />
        </div>
        <div className="logos-slide">
          <img className='hi' src={slidereview1} alt="Slide 1" />
          <img className='hi' src={slidereview2} alt="Slide 2" />
          <img className='hi' src={slidereview3} alt="Slide 3" />
          <img className='hi' src={slidereview4} alt="Slide 4" />
          <img className='hi' src={slidereview5} alt="Slide 5" />
        </div>
        <div className="logos-slide">
          <img className='hi' src={slidereview1} alt="Slide 1" />
          <img className='hi' src={slidereview2} alt="Slide 2" />
          <img className='hi' src={slidereview3} alt="Slide 3" />
          <img className='hi' src={slidereview4} alt="Slide 4" />
          <img className='hi' src={slidereview5} alt="Slide 5" />
        </div>
        <div className="logos-slide">
          <img className='hi' src={slidereview1} alt="Slide 1" />
          <img className='hi' src={slidereview2} alt="Slide 2" />
          <img className='hi' src={slidereview3} alt="Slide 3" />
          <img className='hi' src={slidereview4} alt="Slide 4" />
          <img className='hi' src={slidereview5} alt="Slide 5" />
        </div>
        <div className="logos-slide">
          <img className='hi' src={slidereview1} alt="Slide 1" />
          <img className='hi' src={slidereview2} alt="Slide 2" />
          <img className='hi' src={slidereview3} alt="Slide 3" />
          <img className='hi' src={slidereview4} alt="Slide 4" />
          <img className='hi' src={slidereview5} alt="Slide 5" />
        </div>
      </div>
    </div>
  );
}

export default CardSlide;