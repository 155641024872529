import React from "react"
import "./Modal.css"

const Modal = props => {
  // 열기, 닫기, 모달 헤더 텍스트를 부모로부터 받아옴
  const { open, close, header } = props

  return (
    // 모달이 열릴때 openModal 클래스가 생성된다.
    <div className={open ? "openModal modal" : "modal"}>
      {open ? (
        <section>
          <header>
            {header}
            <button className="close" onClick={close}>
              &times;
            </button>
          </header>
          <main>
            {header == "이용약관" ?
              (<div className="terms-content">
                <h2>제1조 목적</h2>
                <p>
                  본 이용약관은 “EM파트너스”(이하 "사이트")의 서비스의 이용조건과
                  운영에 관한 제반 사항 규정을 목적으로 합니다.
                </p>
                <h2>제2조 용어의 정의</h2>
                <p>
                  본 약관에서 사용되는 주요한 용어의 정의는 다음과 같습니다.
                </p>
                <p>
                  ① 회원 : 사이트의 약관에 동의하고 개인정보를 제공하여
                  회원등록을 한 자로서, 사이트와의 이용계약을 체결하고 사이트를
                  이용하는 이용자를 말합니다.
                  <br />
                  ② 이용계약 : 사이트 이용과 관련하여 사이트와 회원간에 체결 하는
                  계약을 말합니다.
                  <br />
                  ③ 회원 아이디(이하 "ID") : 회원의 식별과 회원의 서비스 이용을
                  위하여 회원별로 부여하는 고유한 문자와 숫자의 조합을 말합니다.
                  <br />
                  ④ 비밀번호 : 회원이 부여받은 ID와 일치된 회원임을 확인하고
                  회원의 권익 보호를 위하여 회원이 선정한 문자와 숫자의 조합을
                  말합니다.
                  <br />
                  ⑤ 운영자 : 서비스에 홈페이지를 개설하여 운영하는 운영자를
                  말합니다.
                  <br />
                  ⑥ 해지 : 회원이 이용계약을 해약하는 것을 말합니다.
                </p>
                <h2>제3조 약관 외 준칙</h2>
                <p>
                  운영자는 필요한 경우 별도로 운영정책을 공지 안내할 수 있으며,
                  본 약관과 운영정책이 중첩될 경우 운영정책이 우선 적용됩니다.
                </p>
                <h2>제4조 이용계약 체결</h2>
                <p>
                  ① 이용계약은 회원으로 등록하여 사이트를 이용하려는 자의 본 약관
                  내용에 대한 동의와 가입신청에 대하여 운영자의 이용승낙으로
                  성립합니다.
                  <br />
                  ② 회원으로 등록하여 서비스를 이용하려는 자는 사이트 가입신청 시
                  본 약관을 읽고 아래에 있는 "동의합니다"를 선택하는 것으로 본
                  약관에 대한 동의 의사 표시를 합니다.
                </p>
                <h2>제5조 서비스 이용 신청</h2>
                <p>
                  ① 회원으로 등록하여 사이트를 이용하려는 이용자는 사이트에서
                  요청하는 제반정보(이용자ID,비밀번호, 닉네임 등)를 제공해야
                  합니다.
                  <br />
                  ② 타인의 정보를 도용하거나 허위의 정보를 등록하는 등 본인의
                  진정한 정보를 등록하지 않은 회원은 사이트 이용과 관련하여
                  아무런 권리를 주장할 수 없으며, 관계 법령에 따라 처벌받을 수
                  있습니다.
                </p>
                <h2>제6조 개인정보처리방침</h2>
                <p>
                  사이트 및 운영자는 회원가입 시 제공한 개인정보 중 비밀번호를
                  가지고 있지 않으며 이와 관련된 부분은 사이트의
                  개인정보처리방침을 따릅니다. 운영자는 관계 법령이 정하는 바에
                  따라 회원등록정보를 포함한 회원의 개인정보를 보호하기 위하여
                  노력합니다.
                  <br />
                  회원의 개인정보보호에 관하여 관계법령 및 사이트가 정하는
                  개인정보처리방침에 정한 바에 따릅니다.
                  <br />
                  단, 회원의 귀책 사유로 인해 노출된 정보에 대해 운영자는 일체의
                  책임을 지지 않습니다. 운영자는 회원이 미풍양속에 저해되거나
                  국가안보에 위배되는 게시물 등 위법한 게시물을 등록 · 배포할
                  경우 관련 기관의 요청이 있을 시 회원의 자료를 열람 및 해당
                  자료를 관련 기관에 제출할 수 있습니다.
                </p>
                <h2>제7조 운영자의 의무</h2>
                <p>
                  ① 운영자는 이용회원으로부터 제기되는 의견이나 불만이 정당하다고
                  인정할 경우에는 가급적 빨리 처리하여야 합니다. 다만, 개인적인
                  사정으로 신속한 처리가 곤란한 경우에는 사후에 공지 또는
                  이용회원에게 쪽지, 전자우편 등을 보내는 등 최선을 다합니다.
                  <br />
                  ② 운영자는 계속적이고 안정적인 사이트 제공을 위하여 설비에
                  장애가 생기거나 유실된 때에는 이를 지체 없이 수리 또는 복구할
                  수 있도록 사이트에 요구할 수 있습니다. 다만, 천재지변 또는
                  사이트나 운영자에 부득이한 사유가 있는 경우, 사이트 운영을 일시
                  정지할 수 있습니다.
                </p>
                <h2>제8조 회원의 의무</h2>
                <p>
                  ① 회원은 본 약관에서 규정하는 사항과 운영자가 정한 제반 규정,
                  공지사항 및 운영정책 등 사이트가 공지하는 사항 및 관계 법령을
                  준수하여야 하며, 기타 사이트의 업무에 방해가 되는 행위,
                  사이트의 명예를 손상하는 행위를 해서는 안 됩니다.
                  <br />
                  ② 회원은 사이트의 명시적 동의가 없는 한 서비스의 이용 권한,
                  기타 이용계약상 지위를 타인에게 양도, 증여할 수 없으며, 이를
                  담보로 제공할 수 없습니다.
                  <br />
                  ③ 이용고객은 아이디 및 비밀번호 관리에 상당한 주의를 기울여야
                  하며, 운영자나 사이트의 동의 없이 제3자에게 아이디를 제공하여
                  이용하게 할 수 없습니다.
                  <br />
                  ④ 회원은 운영자와 사이트 및 제3자의 지적 재산권을 침해해서는 안
                  됩니다.
                </p>
                <h2>제9조 서비스 이용 시간</h2>
                <p>
                  ① 서비스 이용 시간은 업무상 또는 기술상 특별한 지장이 없는 한
                  연중무휴 1일 24시간을 원칙으로 합니다. 단, 사이트는 시스템
                  정기점검, 증설 및 교체를 위해 사이트가 정한 날이나 시간에
                  서비스를 일시중단 할 수 있으며 예정된 작업으로 인한 서비스 일시
                  중단은 사이트의 홈페이지에 사전에 공지하오니 수시로 참고하시길
                  바랍니다.
                  <br />
                  ② 단, 사이트는 다음 경우에 대하여 사전 공지나 예고 없이
                  서비스를 일시적 혹은 영구적으로 중단할 수 있습니다.
                  <ul>
                    <li>긴급한 시스템 점검, 증설, 교체, 고장 혹은 오동작을 일으키는 경우</li>
                    <li>국가비상사태, 정전, 천재지변 등의 불가항력적인 사유가 있는 경우</li>
                    <li>전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지한 경우</li>
                    <li>서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우</li>
                  </ul>
                  ③ 전항에 의한 서비스 중단의 경우 사이트는 사전에 공지사항 등을
                  통하여 회원에게 통지합니다. 단, 사이트가 통제할 수 없는 사유로
                  발생한 서비스의 중단에 대하여 사전공지가 불가능한 경우에는
                  사후공지로 대신합니다.
                </p>
                <h2>제10조 서비스 이용 해지</h2>
                <p>
                  ① 회원이 사이트와의 이용계약을 해지하고자 하는 경우에는 회원
                  본인이 온라인을 통하여 등록해지 신청을 하여야 합니다. 한편,
                  사이트 이용 해지와 별개로 사이트에 대한 이용계약 해지는 별도로
                  하셔야 합니다.
                  <br />
                  ② 해지 신청과 동시에 사이트가 제공하는 사이트 관련 프로그램이
                  회원 관리 화면에서 자동적으로 삭제됨으로 운영자는 더 이상
                  해지신청자의 정보를 볼 수 없습니다.
                </p>
                <h2>제11조 서비스 이용 제한</h2>
                <p>
                  회원은 다음 각호에 해당하는 행위를 하여서는 아니 되며 해당
                  행위를 한 경우에 사이트는 회원의 서비스 이용 제한 및 적법한
                  조치를 할 수 있으며 이용계약을 해지하거나 기간을 정하여
                  서비스를 중지할 수 있습니다.
                  <ul>
                    <li>회원 가입시 혹은 가입 후 정보 변경 시 허위 내용을 등록하는 행위</li>
                    <li>타인의 사이트 이용을 방해하거나 정보를 도용하는 행위</li>
                    <li>사이트의 운영진, 직원 또는 관계자를 사칭하는 행위</li>
                    <li>사이트, 기타 제3자의 인격권 또는 지적재산권을 침해하거나 업무를 방해하는 행위</li>
                    <li>다른 회원의 ID를 부정하게 사용하는 행위</li>
                    <li>다른 회원에 대한 개인정보를 그 동의 없이 수집, 저장, 공개하는 행위</li>
                    <li>범죄와 결부된다고 객관적으로 판단되는 행위</li>
                    <li>기타 관련 법령에 위배되는 행위</li>
                  </ul>
                </p>
                <h2>제12조 게시물의 관리</h2>
                <p>
                  ① 사이트의 게시물과 자료의 관리 및 운영의 책임은 운영자에게
                  있습니다. 운영자는 항상 불량 게시물 및 자료에 대하여 모니터링을
                  하여야 하며, 불량 게시물 및 자료를 발견하거나 신고를 받으면
                  해당 게시물 및 자료를 삭제하고 이를 등록한 회원에게 주의를
                  주어야 합니다. 한편, 이용회원이 올린 게시물에 대해서는 게시자
                  본인에게 책임이 있으니 회원 스스로 본 이용약관에서 위배되는
                  게시물은 게재해서는 안 됩니다.
                  <br />
                  ② 정보통신윤리위원회 등 공공기관의 시정요구가 있는 경우 운영자는
                  회원의 사전동의 없이 게시물을 삭제하거나 이동 할 수 있습니다.
                  <br />
                  ③ 불량게시물의 판단기준은 다음과 같습니다.
                  <ul>
                    <li>다른 회원 또는 제3자에게 심한 모욕을 주거나 명예를 손상하는 내용인 경우</li>
                    <li>공공질서 및 미풍양속에 위반되는 내용을 유포하거나 링크 시키는 경우</li>
                    <li>불법 복제 또는 해킹을 조장하는 내용인 경우</li>
                    <li>영리를 목적으로 하는 광고일 경우</li>
                    <li>범죄와 결부된다고 객관적으로 인정되는 내용일 경우</li>
                    <li>다른 이용자 또는 제3자와 저작권 등 기타 권리를 침해하는 경우</li>
                    <li>기타 관계 법령에 위배된다고 판단되는 경우</li>
                  </ul>
                  ④ 사이트 및 운영자는 게시물 등에 대하여 제3자로부터 명예훼손,
                  지적재산권 등의 권리 침해를 이유로 게시중단 요청을 받은 경우
                  이를 임시로 게시 중단(전송중단)할 수 있으며, 게시중단 요청자와
                  게시물 등록자 간에 소송, 합의 기타 이에 준하는 관련 기관의
                  결정 등이 이루어져 사이트에 접수된 경우 이에 따릅니다.
                </p>
                <h2>제13조 게시물의 보관</h2>
                <p>
                  사이트 운영자가 불가피한 사정으로 본 사이트를 중단하게 될 경우,
                  회원에게 사전 공지를 하고 게시물의 이전이 쉽도록 모든 조치를 하기
                  위해 노력합니다.
                </p>
                <h2>제14조 게시물에 대한 저작권</h2>
                <p>
                  ① 회원이 사이트 내에 게시한 게시물의 저작권은 게시한 회원에게
                  귀속됩니다. 또한 사이트는 게시자의 동의 없이 게시물을 상업적으로
                  이용할 수 없습니다. 다만 비영리 목적인 경우는 그러하지 아니하며,
                  또한 서비스 내의 게재권을 갖습니다.
                  <br />
                  ② 회원은 서비스를 이용하여 취득한 정보를 임의 가공, 판매하는
                  행위 등 서비스에 게재된 자료를 상업적으로 사용할 수 없습니다.
                  <br />
                  ③ 운영자는 회원이 게시하거나 등록하는 사이트 내의 내용물, 게시
                  내용에 대해 제12조 각호에 해당한다고 판단되는 경우 사전통지 없이
                  삭제하거나 이동 또는 등록 거부할 수 있습니다.
                </p>
                <h2>제15조 손해배상</h2>
                <p>
                  ① 본 사이트의 발생한 모든 민, 형법상 책임은 회원 본인에게
                  1차적으로 있습니다.
                  <br />
                  ② 본 사이트로부터 회원이 받은 손해가 천재지변 등 불가항력적이거나
                  회원의 고의 또는 과실로 인하여 발생한 때에는 손해배상을 하지
                  않습니다.
                </p>
                <h2>제16조 면책</h2>
                <p>
                  ① 운영자는 회원이 사이트의 서비스 제공으로부터 기대되는 이익을
                  얻지 못하였거나 서비스 자료에 대한 취사선택 또는 이용으로
                  발생하는 손해 등에 대해서는 책임이 면제됩니다.
                  <br />
                  ② 운영자는 본 사이트의 서비스 기반 및 타 통신업자가 제공하는
                  전기통신 서비스의 장애로 인한 경우에는 책임이 면제되며 본
                  사이트의 서비스 기반과 관련되어 발생한 손해에 대해서는 사이트의
                  이용약관에 준합니다
                  <br />
                  ③ 운영자는 회원이 저장, 게시 또는 전송한 자료와 관련하여 일체의
                  책임을 지지 않습니다.
                  <br />
                  ④ 운영자는 회원의 귀책 사유로 인하여 서비스 이용의 장애가 발생한
                  경우에는 책임지지 아니합니다.
                  <br />
                  ⑤ 운영자는 회원 상호 간 또는 회원과 제3자 상호 간, 기타 회원의 본
                  서비스 내외를 불문한 일체의 활동(데이터 전송, 기타 커뮤니티
                  활동 포함)에 대하여 책임을 지지 않습니다.
                  <br />
                  ⑥ 운영자는 회원이 게시 또는 전송한 자료 및 본 사이트로 회원이
                  제공받을 수 있는 모든 자료들의 진위, 신뢰도, 정확성 등 그 내용에
                  대해서는 책임지지 아니합니다.
                  <br />
                  ⑦ 운영자는 회원 상호 간 또는 회원과 제3자 상호 간에 서비스를
                  매개로 하여 물품거래 등을 한 경우에 그로부터 발생하는 일체의
                  손해에 대하여 책임지지 아니합니다.
                  <br />
                  ⑧ 운영자는 운영자의 귀책 사유 없이 회원간 또는 회원과 제3자간에
                  발생한 일체의 분쟁에 대하여 책임지지 아니합니다.
                  <br />
                  ⑨ 운영자는 서버 등 설비의 관리, 점검, 보수, 교체 과정 또는
                  소프트웨어의 운용 과정에서 고의 또는 고의에 준하는 중대한 과실
                  없이 발생할 수 있는 시스템의 장애, 제3자의 공격으로 인한
                  시스템의 장애, 국내외의 저명한 연구기관이나 보안 관련 업체에 의해
                  대응 방법이 개발되지 아니한 컴퓨터 바이러스 등의 유포나 기타
                  운영자가 통제할 수 없는 불가항력적 사유로 인한 회원의 손해에
                  대하여 책임지지 않습니다.
                </p>
                <div className="contact-info">
                  <h3>부칙</h3>
                  <p>
                    이 약관은 <strong>2024.6.11</strong>부터 시행합니다.
                  </p>
                </div>
              </div>)
              :
              (<div className="privacy-policy">

                <p>
                  우리 헌법은 국민의 기본권인 사생활의 비밀과 자유 및 통신의
                  비밀을 보장하고 있으므로 도/감청 등에 의한 개인정보 및
                  사생활의 은밀한 탐지는 원칙적으로 불법입니다. 그러나 우리
                  사회에 횡행하는 불법 도청, 통신상의 정보 유출로 인하여 심각한
                  인권 침해가 나타나고 있고 국민 개개인의 자유로운 생활형성이
                  위협 받고 있습니다. 이러한 기본권 침해의 소지를 원천적으로
                  제거함으로써 EM파트너스 회원의 프라이버시를 철저히 보호하여
                  정보화 사회에서의 통신의 자유를 보장하고자 아래와 같이
                  개인정보보호정책을 명시합니다. 한국정책자금지원센터
                  개인정보보호정책은 정부의 법률 및 지침의 변경과 EM파트너스의
                  정책 변화에 따라 변경될 수 있습니다. 회원님께서는
                  EM파트너스 사이트 방문 시에 수시로 확인하시기 바랍니다.
                </p>
                <h3>1. 개인정보의 수집목적 및 이용</h3>
                <p>
                  EM파트너스 회원님 개인의 정보를 수집하는 목적은
                  EM파트너스사이트를 통하여 회원님께 최적의 맞춤화된 서비스를
                  제공해드리기 위한 것입니다. EM파트너스는 각종의 컨텐츠를 무료
                  및 유료로 서비스해 드리고 있습니다. 이때 회원님께서 제공해주신
                  개인정보를 바탕으로 회원님께 보다 더 유용한 정보를 선택적으로
                  제공하는 것이 가능하게 됩니다. EM파트너스는 각종 서비스를
                  제공하기 위해서 광고를 게재합니다. 이때에도 회원님 개인에 대한
                  정보를 바탕으로 좀더 유용한 정보로서의 가치가 있는 광고를
                  선별적으로 전달할 수 있게 됩니다.
                </p>
                <h3>2. 수집하는 개인정보 항목 및 수집방법</h3>
                <p>
                  EM파트너스는 최초 회원가입을 하실 때 서비스 제공을 위해 가장
                  필수적인 개인정보를 받고 있습니다. 회원가입 시에 받는 정보는
                  회원님의 생년월일, 아이디, 결혼, 별명, 추천인 아이디,
                  패스워드, 성명, e-mail, 주소, 전화번호, 휴대전화 등입니다.
                  이외에 특정 서비스를 제공하기 위하여 추가적인 정보제공을
                  요청하고 있습니다. 실명확인을 위한 휴대폰번호, 기타
                  서비스제공에 필요한 추가정보의 기재를 요청하게 됩니다. 또한
                  설문조사나 이벤트 시에 집단적인 통계분석을 위해서나 경품발송을
                  위한 목적으로도 개인정보 기재를 요청할 수 있습니다. 이때에도
                  기입하신 정보는 해당서비스 제공이나 회원님께 사전에 밝힌 목적
                  이외의 다른 어떠한 목적으로도 사용되지 않음을 알려드립니다.
                </p>
                <h3>3. 개인정보의 보유 및 폐기</h3>
                <p>
                  귀하가 EM파트너스의 회원으로서 한국정책자금지원센터가 제공하는
                  서비스를 받는 동안 회원님의 개인정보는 EM파트너스에서 계속
                  보유하며 서비스 제공을 위해 이용하게 됩니다. 다만 EM파트너스의
                  회원이 아래의 '7. 자신의 개인정보 열람, 정정 및 삭제'에서
                  설명한 절차에 따라 ID를 삭제하거나 가입해지를 요청한 경우와
                  회원님께 사전에 알려드린 개인정보를 제공받은 목적이 달성된
                  경우에 수집된 개인의 정보는 재생할 수 없는 방법에 의하여
                  하드디스크에서 완전히 삭제되며 어떠한 용도로도 열람 또는
                  이용할 수 없도록 처리됩니다.
                </p>
                <h3>4. 개인정보의 제공 및 공유</h3>
                <p>
                  원칙적으로 EM파트너스 회원님의 개인정보를 타인 또는 타기업·기관에
                  공개하지 않습니다. 다만 회원님이 공개에 동의한 경우 또는
                  EM파트너스의 서비스 이용약관을 위배하는 사람이나 EM파트너스의
                  서비스를 이용하여 타인에게 법적인 피해를 주거나 미풍양속을
                  해치는 행위를 한 사람 등에게 법적인 조치를 취하기 위하여
                  개인정보를 공개해야 한다고 판단되는 충분한 근거가 있는 경우는
                  예외로 합니다. EM파트너스는 보다 나은 서비스 제공을 위해
                  회원님의 개인정보를 비즈니스 파트너와 공유할 수 있습니다. 이
                  경우에도 정보수집 또는 정보제공 이전에 회원님께 비즈니스
                  파트너가 누구인지, 어떤 정보가 왜 필요한지, 그리고 언제까지
                  어떻게 보호/관리되는지 알려드리고 동의를 구하는 절차를
                  거치게 되며, 회원님께서 동의하지 않는 경우에는 추가적인 정보를
                  수집하거나 비즈니스 파트너와 공유하지 않습니다. 성별, 연령별
                  기타 특정 조건의 집단에 대한 광고 게재 및 발송 시(예, 20대
                  여자)에도 회원님 개인의 정보는 광고를 의뢰한 개인이나 단체에
                  제공되지 않습니다. 기타 통계처리, 학술연구, 시장조사를
                  위하여 필요한 경우에는 특정한 개인을 식별할 수 없는 형태로만
                  정보가 제공됩니다.
                </p>
                <h3>5. 쿠키(cookie)의 운용 및 활용</h3>
                <p>
                  회원님 개개인에게 개인화되고(personalized) 맞춤화된(customized)
                  서비스를 제공하기 위해서 EM파트너스는 회원님의 정보를 저장하고
                  수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트를
                  운영하는데 이용되는 서버가 사용자의 브라우저에게 보내는
                  조그마한 데이터 꾸러미로 회원님 컴퓨터의 하드디스크에
                  저장됩니다. 회원님께서 EM파트너스에 접속한 후 로그인(log-in)하여
                  개인화된 서비스나 맞춤화된 서비스를 이용하시기 위해서는 쿠키를
                  허용하여야 합니다. EM파트너스는 회원님께 적합하고 보다 유용한
                  서비스를 제공하기 위해서 쿠키를 이용하여 회원님의 아이디에
                  대한 정보를 찾아냅니다. EM파트너스에 접속하는 이용자의
                  브라우저에 고유한 쿠키를 부여함으로써 회원 및 비회원들의
                  EM파트너스사이트 이용빈도나 전체 이용자수 등과 같은 이용자
                  규모를 파악하는데도 이용됩니다. 또한 EM파트너스에서 상품구매 시
                  인증을 위해서나 상품구매를 돕기 위해서도 쿠키 정보를 이용하며,
                  기타 이벤트나 설문조사에서 회원님의 참여 경력을 확인하기 위해서
                  쿠키를 이용하게 됩니다. 쿠키를 이용하여 회원님께서 방문한
                  EM파트너스서비스의 방문 및 이용형태를 파악함으로써 더 유용하고
                  이용하기 편리한 서비스를 만들어 제공할 수 있게 됩니다. 회원님은
                  쿠키에 대한 선택권을 가지고 있습니다. 회원님의 웹브라우저에서
                  옵션을 선택함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다
                  확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다. 단,
                  쿠키의 저장을 거부하실 경우 로그인이 필요한 EM파트너스의 모든
                  서비스는 이용하실 수 없게 됩니다.
                </p>
                <h3>6. 개인정보보호를 위한 기술적/제도적 관리</h3>
                <p>
                  회원님의 개인정보는 비밀번호에 의해 보호되고 있습니다. 회원님
                  계정의 비밀번호는 오직 본인만이 알 수 있으며, 개인정보의 확인
                  및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.
                  따라서 회원님의 비밀번호는 누구에게도 알려주면 안됩니다. 또한
                  작업을 마치신 후에는 로그아웃(log-out)하시고 웹브라우저를
                  종료하는 것이 바람직합니다. 특히 다른 사람과 컴퓨터를 공유하여
                  사용하거나 공공장소에서 이용한 경우 개인정보가 다른 사람에게
                  알려지는 것을 막기 위해서 이와 같은 절차가 더욱 필요하다고
                  하겠습니다. EM파트너스는 백신프로그램을 이용하여 컴퓨터바이러스에
                  의한 피해를 방지하기 위한 조치를 취하고 있습니다.
                  백신프로그램은 주기적으로 업데이트 되며 갑작스런 바이러스가
                  출현할 경우 백신이 나오는 즉시 이를 제공함으로써 개인정보가
                  훼손되는 것을 방지하고 있습니다. 해킹 등에 의해 회원의 개인정보가
                  유출되는 것을 막기 위해 현재 외부로부터의 침입을 차단하는 장치를
                  이용하여 외부로부터의 공격, 해킹 등을 막고 있으며, 각 서버마다
                  침입탐지시스템을 설치하여 24시간 침입을 감시하고 있습니다. 기타
                  회원 개인정보의 훼손에 대비해서 시스템과 데이터를 백업하여
                  만약의 사태에 대비하고 있습니다. 한국정책자금지원센터는 개인정보
                  취급 직원을 최소한으로 제한하고 담당직원에 대한 수시 교육을
                  통하여 본 정책의 준수를 강조하고 있으며, 감사위원회의 감사를
                  통하여 본 정책의 이행사항 및 담당직원의 준수여부를 확인하여
                  문제가 발견될 경우 바로 시정조치하고 있습니다.
                </p>
                <h3>7. 자신의 개인정보 열람, 정정 및 삭제</h3>
                <p>
                  회원님은 언제든지 등록되어 있는 회원님의 개인정보를 열람하거나
                  정정하실 수 있으며, 아이디(ID) 삭제를 요청하실 수 있습니다.
                  개인정보와 관련하여 불만이나 의견이 있으신 분은 개인정보
                  관리담당자에게 메일 EM파트너스로 의견을 주시면 접수 즉시
                  조치하고 처리결과를 통보해 드리겠습니다.
                </p>
                <h3>8. 어린이의 개인정보보호</h3>
                <p>
                  만14세 미만의 어린이는 자신에 대한 정보를 다른 사람에게 함부로
                  보내면 안되며, 보내기 전에 반드시 부모님의 허락을 받아야
                  합니다. 회원님 계정의 비밀번호에 대한 보안을 유지할 책임은
                  회원님 자신에게 있습니다. EM파트너스에서 메일 또는 기타 방법으로
                  회원님께 비밀번호를 질문하는 경우는 절대 없으므로 어떠한
                  경우에도 비밀번호를 알려주지 마십시오. 로그온(log-on)한 상태에서는
                  주위의 다른 사람에게 개인정보가 유출되지 않도록 특별히 주의를
                  기울이시기 바랍니다.
                </p>
                <div className="contact-info">
                  <h3>개인정보 관리담당자</h3>
                  <p>
                    이 름: 오태우(홈페이지 관리)
                    <br />
                    TEL: 1688-7510
                    <br />
                    이메일: empartners@gmail.com
                    <br />
                    이상의 변경된 EM파트너스의 개인정보보호정책은 2024년 06월
                    11일부터 시행합니다.
                  </p>
                </div>
              </div>)}
          </main>
          <footer>
            <button className="close" onClick={close}>
              닫기
            </button>
          </footer>
        </section>
      ) : null}
    </div>
  )
}

export default Modal