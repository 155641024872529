import React from 'react';
import '../App.css';
import './Organ.css';

import SuccessReviewText from '../assets/images/success_review_text.png';

import Oganlogo1 from '../assets/images/oganlogo (1).jpg';
import Oganlogo2 from '../assets/images/oganlogo (2).jpg';
import Oganlogo3 from '../assets/images/oganlogo (3).jpg';
import Oganlogo4 from '../assets/images/oganlogo (4).jpg';
import Oganlogo5 from '../assets/images/oganlogo (5).jpg';
import Oganlogo6 from '../assets/images/oganlogo (6).jpg';
// 248 x 270
function Organ() {
  return (
    <div className="orga-container">
      <div className="orgas">
        <div className="orgas-slide">
          <a href='https://www.kodit.co.kr/index.do' target='_blank'>
            <img className='hi' src={Oganlogo1} alt="Slide 1" />
          </a>
          <a href='https://www.koreg.or.kr/intro/main.do' target='_blank'>
            <img className='hi' src={Oganlogo2} alt="Slide 1" />
          </a>
          <a href='https://www.mss.go.kr/site/smba/main.do' target='_blank'>
            <img className='hi' src={Oganlogo3} alt="Slide 1" />
          </a>
          <a href='https://www.kibo.or.kr/index.do' target='_blank'>
            <img className='hi' src={Oganlogo4} alt="Slide 1" />
          </a>
          <a href='https://www.kosmes.or.kr/intro/intro_real.html' target='_blank'>
            <img className='hi' src={Oganlogo5} alt="Slide 1" />
          </a>
          <a href='https://www.semas.or.kr/web/main/index.kmdc' target='_blank'>
            <img className='hi' src={Oganlogo6} alt="Slide 1" />
          </a>
        </div>
        <div className="orgas-slide">
          <a href='https://www.kodit.co.kr/index.do' target='_blank'>
            <img className='hi' src={Oganlogo1} alt="Slide 1" />
          </a>
          <a href='https://www.koreg.or.kr/intro/main.do' target='_blank'>
            <img className='hi' src={Oganlogo2} alt="Slide 1" />
          </a>
          <a href='https://www.mss.go.kr/site/smba/main.do' target='_blank'>
            <img className='hi' src={Oganlogo3} alt="Slide 1" />
          </a>
          <a href='https://www.kibo.or.kr/index.do' target='_blank'>
            <img className='hi' src={Oganlogo4} alt="Slide 1" />
          </a>
          <a href='https://www.kosmes.or.kr/intro/intro_real.html' target='_blank'>
            <img className='hi' src={Oganlogo5} alt="Slide 1" />
          </a>
          <a href='https://www.semas.or.kr/web/main/index.kmdc' target='_blank'>
            <img className='hi' src={Oganlogo6} alt="Slide 1" />
          </a>
        </div>
        <div className="orgas-slide">
          <a href='https://www.kodit.co.kr/index.do' target='_blank'>
            <img className='hi' src={Oganlogo1} alt="Slide 1" />
          </a>
          <a href='https://www.koreg.or.kr/intro/main.do' target='_blank'>
            <img className='hi' src={Oganlogo2} alt="Slide 1" />
          </a>
          <a href='https://www.mss.go.kr/site/smba/main.do' target='_blank'>
            <img className='hi' src={Oganlogo3} alt="Slide 1" />
          </a>
          <a href='https://www.kibo.or.kr/index.do' target='_blank'>
            <img className='hi' src={Oganlogo4} alt="Slide 1" />
          </a>
          <a href='https://www.kosmes.or.kr/intro/intro_real.html' target='_blank'>
            <img className='hi' src={Oganlogo5} alt="Slide 1" />
          </a>
          <a href='https://www.semas.or.kr/web/main/index.kmdc' target='_blank'>
            <img className='hi' src={Oganlogo6} alt="Slide 1" />
          </a>
        </div>
        <div className="orgas-slide">
          <a href='https://www.kodit.co.kr/index.do' target='_blank'>
            <img className='hi' src={Oganlogo1} alt="Slide 1" />
          </a>
          <a href='https://www.koreg.or.kr/intro/main.do' target='_blank'>
            <img className='hi' src={Oganlogo2} alt="Slide 1" />
          </a>
          <a href='https://www.mss.go.kr/site/smba/main.do' target='_blank'>
            <img className='hi' src={Oganlogo3} alt="Slide 1" />
          </a>
          <a href='https://www.kibo.or.kr/index.do' target='_blank'>
            <img className='hi' src={Oganlogo4} alt="Slide 1" />
          </a>
          <a href='https://www.kosmes.or.kr/intro/intro_real.html' target='_blank'>
            <img className='hi' src={Oganlogo5} alt="Slide 1" />
          </a>
          <a href='https://www.semas.or.kr/web/main/index.kmdc' target='_blank'>
            <img className='hi' src={Oganlogo6} alt="Slide 1" />
          </a>
        </div>
        <div className="orgas-slide">
          <a href='https://www.kodit.co.kr/index.do' target='_blank'>
            <img className='hi' src={Oganlogo1} alt="Slide 1" />
          </a>
          <a href='https://www.koreg.or.kr/intro/main.do' target='_blank'>
            <img className='hi' src={Oganlogo2} alt="Slide 1" />
          </a>
          <a href='https://www.mss.go.kr/site/smba/main.do' target='_blank'>
            <img className='hi' src={Oganlogo3} alt="Slide 1" />
          </a>
          <a href='https://www.kibo.or.kr/index.do' target='_blank'>
            <img className='hi' src={Oganlogo4} alt="Slide 1" />
          </a>
          <a href='https://www.kosmes.or.kr/intro/intro_real.html' target='_blank'>
            <img className='hi' src={Oganlogo5} alt="Slide 1" />
          </a>
          <a href='https://www.semas.or.kr/web/main/index.kmdc' target='_blank'>
            <img className='hi' src={Oganlogo6} alt="Slide 1" />
          </a>
        </div>
        <div className="orgas-slide">
          <a href='https://www.kodit.co.kr/index.do' target='_blank'>
            <img className='hi' src={Oganlogo1} alt="Slide 1" />
          </a>
          <a href='https://www.koreg.or.kr/intro/main.do' target='_blank'>
            <img className='hi' src={Oganlogo2} alt="Slide 1" />
          </a>
          <a href='https://www.mss.go.kr/site/smba/main.do' target='_blank'>
            <img className='hi' src={Oganlogo3} alt="Slide 1" />
          </a>
          <a href='https://www.kibo.or.kr/index.do' target='_blank'>
            <img className='hi' src={Oganlogo4} alt="Slide 1" />
          </a>
          <a href='https://www.kosmes.or.kr/intro/intro_real.html' target='_blank'>
            <img className='hi' src={Oganlogo5} alt="Slide 1" />
          </a>
          <a href='https://www.semas.or.kr/web/main/index.kmdc' target='_blank'>
            <img className='hi' src={Oganlogo6} alt="Slide 1" />
          </a>
        </div>
        <div className="orgas-slide">
          <a href='https://www.kodit.co.kr/index.do' target='_blank'>
            <img className='hi' src={Oganlogo1} alt="Slide 1" />
          </a>
          <a href='https://www.koreg.or.kr/intro/main.do' target='_blank'>
            <img className='hi' src={Oganlogo2} alt="Slide 1" />
          </a>
          <a href='https://www.mss.go.kr/site/smba/main.do' target='_blank'>
            <img className='hi' src={Oganlogo3} alt="Slide 1" />
          </a>
          <a href='https://www.kibo.or.kr/index.do' target='_blank'>
            <img className='hi' src={Oganlogo4} alt="Slide 1" />
          </a>
          <a href='https://www.kosmes.or.kr/intro/intro_real.html' target='_blank'>
            <img className='hi' src={Oganlogo5} alt="Slide 1" />
          </a>
          <a href='https://www.semas.or.kr/web/main/index.kmdc' target='_blank'>
            <img className='hi' src={Oganlogo6} alt="Slide 1" />
          </a>
        </div>
        <div className="orgas-slide">
          <a href='https://www.kodit.co.kr/index.do' target='_blank'>
            <img className='hi' src={Oganlogo1} alt="Slide 1" />
          </a>
          <a href='https://www.koreg.or.kr/intro/main.do' target='_blank'>
            <img className='hi' src={Oganlogo2} alt="Slide 1" />
          </a>
          <a href='https://www.mss.go.kr/site/smba/main.do' target='_blank'>
            <img className='hi' src={Oganlogo3} alt="Slide 1" />
          </a>
          <a href='https://www.kibo.or.kr/index.do' target='_blank'>
            <img className='hi' src={Oganlogo4} alt="Slide 1" />
          </a>
          <a href='https://www.kosmes.or.kr/intro/intro_real.html' target='_blank'>
            <img className='hi' src={Oganlogo5} alt="Slide 1" />
          </a>
          <a href='https://www.semas.or.kr/web/main/index.kmdc' target='_blank'>
            <img className='hi' src={Oganlogo6} alt="Slide 1" />
          </a>
        </div>


      </div>
    </div>
  );
}

export default Organ;