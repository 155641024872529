import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button } from './Button';
import './Navbar.css';
import Logo from '../assets/images/logo_blue.png';
// import LogoBlue from '../assets/images/cut_blue_logo.png';
import LogoBlue from '../assets/images/logo_blue_circle.png';

function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true)

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false)
        } else {
            setButton(true)
        }
    };

    window.addEventListener('resize', showButton);

    let currentUrl = window.location.pathname;

    const [scrollPosition, setScrollPosition] = useState(0);
    const [headerColor, setHeaderColor] = useState("#ffffff");
    const [LogoColor, setLogoColor] = useState("#ffffff");

    // Scroll 위치를 감지
    const updateScroll = () => {
        setScrollPosition(window.scrollY || document.documentElement.scrollTop);
    };

    useEffect(() => {
        window.addEventListener("scroll", updateScroll);
        return () => {
            window.removeEventListener("scroll", updateScroll);
        };
    }, []);

    // scroll 위치가 100이하라면 투명한 배경색을 지정하고, 아니면 흰색을 지정한다.
    useEffect(() => {
        if (currentUrl === "/" && scrollPosition < 100) {
            setHeaderColor(true);
            setLogoColor(true)
        } else {
            setHeaderColor(false);
            setLogoColor(false)
        }
    }, [scrollPosition, currentUrl]);


    return (
        <>
            <nav className={headerColor ? "original_header" : "change_header"}>
                <div className='navbar-container'>

                    <Link to="/" className='navbar-logo'>
                        <img className='navbar-logo-detail' src={LogoBlue} alt="Logo" />
                        {/* <p className={LogoColor ? "logo-text" : "change_logo-text"}>EM파트너스</p> */}
                        {/* <i className='fab fa-typo3' /> */}
                    </Link>
                    {/* <div className='navbar-logo'>
                        <div className='navbar-logo-blue-detail'></div>
                    </div> */}
                    
                    {/* <div className='menu-icon' onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                    </div> */}

                </div>
            </nav>
        </>
    )
}

export default Navbar

{/* <ul className={click ? 'nav-menu active' : 'nav-menu'}> */}
{/* <li>
<Link to='/company' className='nav-links' onClick={closeMobileMenu}>
회사소개
</Link>
</li> */}
{/* <li>
    <Link to='/service' className='nav-links' onClick={closeMobileMenu}>
        회사소개
    </Link>
</li> */}
{/* <li>
    <Link to='/service' className='nav-links' onClick={closeMobileMenu}>
        서비스안내
    </Link>
</li> */}
{/* <li>
<Link to='/contact' className='nav-links' onClick={closeMobileMenu}>
문의하기
</Link>
</li>
<li>
<Link to='/answer' className='nav-links' onClick={closeMobileMenu}>
자주 묻는 질문
</Link>
</li> */}
// </ul>
// {button && <Button buttonStyle='btn--outline'>SIGN UP</Button>}