import React from 'react';
import './Consultation.css';
import CardItem from './CardItem';
import PhoneCall from '../assets/images/phone_call.png';
import WriteButton from '../assets/images/write_button.png';

function Consultation() {
  return (
    <div className='consultation'>

      <div className='consultation__container'>

        <a href='tel:1688-7510' className='phone__call__container' >
          <img className="consultation_image" src={PhoneCall} alt="Logo" />
        </a>
        
        <a href='https://forms.gle/zta7K4Zi7D4Qjbwm6' className='write__container' target='_blank'>
          <img className="consultation_image" src={WriteButton} alt="Logo" />
        </a>

      </div>
    </div>
  );
}

export default Consultation;