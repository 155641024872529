import React, { useState } from 'react';
import './FastConsult.css';

function FastConsult({ closeFastModal }) {
  const [formData, setFormData] = useState({
    businessName: '',
    phonePart1: '',
    phonePart2: '',
    phonePart3: '',
    industry: '',
    location: '',
    consult: '',
  });

  const [consentChecked, setConsentChecked] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const { businessName, phonePart1, phonePart2, phonePart3, industry, location, consult } = formData;

    // Validate form data
    if (!businessName || !phonePart1 || !phonePart2 || !phonePart3 || !industry || !location || !consult) {
      let message = '다음 항목을 입력해 주세요:\n';
      if (!businessName) message += '- 사업자명\n';
      if (!phonePart1 || !phonePart2 || !phonePart3) message += '- 연락처\n';
      if (!industry) message += '- 업종\n';
      if (!location) message += '- 사업장 소재지\n';
      if (!consult) message += '- 상담 내용\n';
      alert(message);
      return;
    }

    if (!consentChecked) {
      alert('개인정보 수집 및 이용에 동의해야 합니다.');
      return;
    }

    const phone = `${phonePart1}${phonePart2}${phonePart3}`;
    const apiToken = process.env.REACT_APP_API_MONDAY_KEY;
    const boardId = '1891944887'; // Replace with your Monday.com board ID
    const currentDate = new Date().toISOString(); // ISO 8601 format for the current date

    const query = `
      mutation {
        create_item (
          board_id: ${boardId}, 
          item_name: "${businessName}", 
          column_values: "${JSON.stringify({
      status: { label: "상담 전" }, // Status column: pre-consultation
      text5: phone, // Phone number
      text: currentDate, // Timestamp
      text3: location, // Location
      text1: industry, // Industry
      consult: { label: consult }, // Consulting type
      color: { label: "홈페이지" }, // 경로
      color6: { label: "안적음" }, // 지원대상
    }).replace(/"/g, '\\"')}") {
          id
        }
      }
    `;
    fetch('https://api.monday.com/v2', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': apiToken,
      },
      body: JSON.stringify({ query }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.errors) {
          console.error('API Errors:', data.errors);
          alert('데이터 전송 중 오류가 발생했습니다.');
        } else {
          const parentId = data.data.create_item.id;

          const subItems = [
            { name: "사업자번호", value: "" },
            { name: "사업자신고일", value: "" },
            { name: "대표자 신용점수", value: "" },
            { name: "국세 / 지방세 현재 기준 체납 여부", value: "" },  // 추가 항목
            { name: "정책자금 수혜 경험 여부", value: "" },
            { name: "4대보험 기준 고용인원", value: "" },
            { name: "보유하신 인증 항목 (기업부설, 특허, 벤처인증 등등)", value: "" },
          ];



          subItems.forEach(subItem => {
            const columnValues = JSON.stringify({
              long_text: subItem.value // 텍스트 컬럼에 값을 설정
            });

            const subItemQuery = `
              mutation {
                create_subitem (
                  parent_item_id: ${parentId}, 
                  item_name: "${subItem.name}", 
                  column_values: "${columnValues.replace(/"/g, '\\"')}"
                ) {
                  id
                }
              }
            `;

            console.log('Sub Item Query:', subItemQuery); // 쿼리 확인용 로그

            fetch('https://api.monday.com/v2', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': apiToken,
              },
              body: JSON.stringify({ query: subItemQuery }),
            })
              .then(subItemResponse => subItemResponse.json())
              .then(subItemData => {
                if (subItemData.errors) {
                  console.error('API Errors:', subItemData.errors);
                  alert(`하위 아이템 ${subItem.name} 생성 중 오류가 발생했습니다.`);
                } else {
                  console.log(`하위 아이템 ${subItem.name} 생성 완료`, subItemData);
                }
              })
              .catch(error => {
                console.error('Error:', error);
                alert(`하위 아이템 ${subItem.name} 생성 중 오류가 발생했습니다.`);
              });
          });

          alert('상담 신청이 완료되었습니다.');
          console.log('Parent Item and Sub Items created successfully');
        }
      })
      .catch(error => {
        console.error('Error:', error);
        alert('데이터 전송 중 오류가 발생했습니다.');
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleConsentChange = (e) => {
    setConsentChecked(e.target.checked);
  };

  return (
    <div className="fastconsult">
      <div className="fastconsult__form__container">
        <button className="close" onClick={closeFastModal}>
          &times;
        </button>
        <h2>빠른 상담 신청</h2>
        <form className="consult__form" onSubmit={handleSubmit}>
          <div className="form__group">
            <label>개인정보 수집 및 이용 동의 *</label>
            <textarea
              readOnly
              rows="5"
              className="textarea__large"
              value="우리 헌법은 국민의 기본권인 사생활의 비밀과 자유 및 통신의 비밀을 보장하고 있으므로 도/감청 등에 의한 개인정보 및 사생활의 은밀한 탐지는 원칙적으로 불법입니다. 그러나 우리 사회에 횡행하는 불법 도청, 통신상의 정보 유출로 인하여 심각한 인권 침해가 나타나고 있고 국민 개개인의 자유로운 생활형성이 위협 받고 있습니다. 이러한 기본권 침해의 소지를 원천적으로 제거함으로써 EM파트너스 회원의 프라이버시를 철저히 보호하여 정보화 사회에서의 통신의 자유를 보장하고자 아래와 같이 개인정보보호정책을 명시합니다. 한국정책자금지원센터 개인정보보호정책은 정부의 법률 및 지침의 변경과 EM파트너스의 정책 변화에 따라 변경될 수 있습니다. 회원님께서는 EM파트너스 사이트 방문 시에 수시로 확인하시기 바랍니다.

1. 개인정보의 수집목적 및 이용
EM파트너스 회원님 개인의 정보를 수집하는 목적은 EM파트너스사이트를 통하여 회원님께 최적의 맞춤화된 서비스를 제공해드리기 위한 것입니다. EM파트너스는 각종의 컨텐츠를 무료 및 유료로 서비스해 드리고 있습니다. 이때 회원님께서 제공해주신 개인정보를 바탕으로 회원님께 보다 더 유용한 정보를 선택적으로 제공하는 것이 가능하게 됩니다. EM파트너스는 각종 서비스를 제공하기 위해서 광고를 게재합니다. 이때에도 회원님 개인에 대한 정보를 바탕으로 좀더 유용한 정보로서의 가치가 있는 광고를 선별적으로 전달할 수 있게 됩니다.

2. 수집하는 개인정보 항목 및 수집방법
EM파트너스는 최초 회원가입을 하실 때 서비스 제공을 위해 가장 필수적인 개인정보를 받고 있습니다. 회원가입 시에 받는 정보는 회원님의 생년월일, 아이디, 결혼, 별명, 추천인 아이디, 패스워드, 성명, e-mail, 주소, 전화번호, 휴대전화 등입니다. 이외에 특정 서비스를 제공하기 위하여 추가적인 정보제공을 요청하고 있습니다. 실명확인을 위한 휴대폰번호, 기타 서비스제공에 필요한 추가정보의 기재를 요청하게 됩니다. 또한 설문조사나 이벤트 시에 집단적인 통계분석을 위해서나 경품발송을 위한 목적으로도 개인정보 기재를 요청할 수 있습니다. 이때에도 기입하신 정보는 해당서비스 제공이나 회원님께 사전에 밝힌 목적 이외의 다른 어떠한 목적으로도 사용되지 않음을 알려드립니다.

3. 개인정보의 보유 및 폐기
귀하가 EM파트너스의 회원으로서 한국정책자금지원센터가 제공하는 서비스를 받는 동안 회원님의 개인정보는 EM파트너스에서 계속 보유하며 서비스 제공을 위해 이용하게 됩니다. 다만 EM파트너스의 회원이 아래의 '7. 자신의 개인정보 열람, 정정 및 삭제'에서 설명한 절차에 따라 ID를 삭제하거나 가입해지를 요청한 경우와 회원님께 사전에 알려드린 개인정보를 제공받은 목적이 달성된 경우에 수집된 개인의 정보는 재생할 수 없는 방법에 의하여 하드디스크에서 완전히 삭제되며 어떠한 용도로도 열람 또는 이용할 수 없도록 처리됩니다.

4. 개인정보의 제공 및 공유
원칙적으로 EM파트너스 회원님의 개인정보를 타인 또는 타기업·기관에 공개하지 않습니다. 다만 회원님이 공개에 동의한 경우 또는 EM파트너스의 서비스 이용약관을 위배하는 사람이나 EM파트너스의 서비스를 이용하여 타인에게 법적인 피해를 주거나 미풍양속을 해치는 행위를 한 사람 등에게 법적인 조치를 취하기 위하여 개인정보를 공개해야 한다고 판단되는 충분한 근거가 있는 경우는 예외로 합니다. EM파트너스는 보다 나은 서비스 제공을 위해 회원님의 개인정보를 비즈니스 파트너와 공유할 수 있습니다. 이 경우에도 정보수집 또는 정보제공 이전에 회원님께 비즈니스 파트너가 누구인지, 어떤 정보가 왜 필요한지, 그리고 언제까지 어떻게 보호/관리되는지 알려드리고 동의를 구하는 절차를 거치게 되며, 회원님께서 동의하지 않는 경우에는 추가적인 정보를 수집하거나 비즈니스 파트너와 공유하지 않습니다. 성별, 연령별 기타 특정 조건의 집단에 대한 광고 게재 및 발송 시(예, 20대 여자)에도 회원님 개인의 정보는 광고를 의뢰한 개인이나 단체에 제공되지 않습니다. 기타 통계처리, 학술연구, 시장조사를 위하여 필요한 경우에는 특정한 개인을 식별할 수 없는 형태로만 정보가 제공됩니다.

5. 쿠키(cookie)의 운용 및 활용
회원님 개개인에게 개인화되고(personalized) 맞춤화된(customized) 서비스를 제공하기 위해서 EM파트너스는 회원님의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버가 사용자의 브라우저에게 보내는 조그마한 데이터 꾸러미로 회원님 컴퓨터의 하드디스크에 저장됩니다. 회원님께서 EM파트너스에 접속한 후 로그인(log-in)하여 개인화된 서비스나 맞춤화된 서비스를 이용하시기 위해서는 쿠키를 허용하여야 합니다. EM파트너스는 회원님께 적합하고 보다 유용한 서비스를 제공하기 위해서 쿠키를 이용하여 회원님의 아이디에 대한 정보를 찾아냅니다. EM파트너스에 접속하는 이용자의 브라우저에 고유한 쿠키를 부여함으로써 회원 및 비회원들의 EM파트너스사이트 이용빈도나 전체 이용자수 등과 같은 이용자 규모를 파악하는데도 이용됩니다. 또한 EM파트너스에서 상품구매 시 인증을 위해서나 상품구매를 돕기 위해서도 쿠키 정보를 이용하며, 기타 이벤트나 설문조사에서 회원님의 참여 경력을 확인하기 위해서 쿠키를 이용하게 됩니다. 쿠키를 이용하여 회원님께서 방문한 EM파트너스서비스의 방문 및 이용형태를 파악함으로써 더 유용하고 이용하기 편리한 서비스를 만들어 제공할 수 있게 됩니다. 회원님은 쿠키에 대한 선택권을 가지고 있습니다. 회원님의 웹브라우저에서 옵션을 선택함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다. 단, 쿠키의 저장을 거부하실 경우 로그인이 필요한 EM파트너스의 모든 서비스는 이용하실 수 없게 됩니다.

6. 개인정보보호를 위한 기술적/제도적 관리
회원님의 개인정보는 비밀번호에 의해 보호되고 있습니다. 회원님 계정의 비밀번호는 오직 본인만이 알 수 있으며, 개인정보의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다. 따라서 회원님의 비밀번호는 누구에게도 알려주면 안됩니다. 또한 작업을 마치신 후에는 로그아웃(log-out)하시고 웹브라우저를 종료하는 것이 바람직합니다. 특히 다른 사람과 컴퓨터를 공유하여 사용하거나 공공장소에서 이용한 경우 개인정보가 다른 사람에게 알려지는 것을 막기 위해서 이와 같은 절차가 더욱 필요하다고 하겠습니다. EM파트너스는 백신프로그램을 이용하여 컴퓨터바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다. 백신프로그램은 주기적으로 업데이트 되며 갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시 이를 제공함으로써 개인정보가 훼손되는 것을 방지하고 있습니다. 해킹 등에 의해 회원의 개인정보가 유출되는 것을 막기 위해 현재 외부로부터의 침입을 차단하는 장치를 이용하여 외부로부터의 공격, 해킹 등을 막고 있으며, 각 서버마다 침입탐지시스템을 설치하여 24시간 침입을 감시하고 있습니다. 기타 회원 개인정보의 훼손에 대비해서 시스템과 데이터를 백업하여 만약의 사태에 대비하고 있습니다. 한국정책자금지원센터는 개인정보 취급 직원을 최소한으로 제한하고 담당직원에 대한 수시 교육을 통하여 본 정책의 준수를 강조하고 있으며, 감사위원회의 감사를 통하여 본 정책의 이행사항 및 담당직원의 준수여부를 확인하여 문제가 발견될 경우 바로 시정조치하고 있습니다.

7. 자신의 개인정보 열람, 정정 및 삭제
회원님은 언제든지 등록되어 있는 회원님의 개인정보를 열람하거나 정정하실 수 있으며, 아이디(ID) 삭제를 요청하실 수 있습니다. 개인정보와 관련하여 불만이나 의견이 있으신 분은 개인정보 관리담당자에게 메일 EM파트너스로 의견을 주시면 접수 즉시 조치하고 처리결과를 통보해 드리겠습니다.

8. 어린이의 개인정보보호
만14세 미만의 어린이는 자신에 대한 정보를 다른 사람에게 함부로 보내면 안되며, 보내기 전에 반드시 부모님의 허락을 받아야 합니다. 회원님 계정의 비밀번호에 대한 보안을 유지할 책임은 회원님 자신에게 있습니다. EM파트너스에서 메일 또는 기타 방법으로 회원님께 비밀번호를 질문하는 경우는 절대 없으므로 어떠한 경우에도 비밀번호를 알려주지 마십시오. 로그온(log-on)한 상태에서는 주위의 다른 사람에게 개인정보가 유출되지 않도록 특별히 주의를 기울이시기 바랍니다.

개인정보 관리담당자
이 름: 오태우(홈페이지 관리)
TEL: 1688-7510
이메일: empartners@gmail.com
이상의 변경된 EM파트너스의 개인정보보호정책은 2024년 06월 11일부터 시행합니다."
            />
            <div className="checkbox__group__inline">
              <input
                type="checkbox"
                id="agree"
                name="agree"
                checked={consentChecked}
                onChange={handleConsentChange}
                required
              />
              <label htmlFor="agree">개인정보 수집 및 이용에 동의합니다.</label>
            </div>
          </div>

          <div className="form__group">
            <label>이름 *</label>
            <input
              type="text"
              className="input__large"
              name="businessName"
              value={formData.businessName}
              onChange={handleChange}
            />
          </div>

          <div className="form__group">
            <label>연락처 *</label>
            <div className="contact__group">
              <input
                type="text"
                maxLength="3"
                className="input__small"
                name="phonePart1"
                value={formData.phonePart1}
                onChange={handleChange}
              />
              <input
                type="text"
                maxLength="4"
                className="input__small"
                name="phonePart2"
                value={formData.phonePart2}
                onChange={handleChange}
              />
              <input
                type="text"
                maxLength="4"
                className="input__small"
                name="phonePart3"
                value={formData.phonePart3}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="form__group">
            <label>업종 *</label>
            <input
              type="text"
              className="input__large"
              name="industry"
              value={formData.industry}
              onChange={handleChange}
            />
          </div>

          <div className="form__group">
            <label>사업장 소재지 *</label>
            <input
              type="text"
              className="input__large"
              name="location"
              value={formData.location}
              onChange={handleChange}
            />
          </div>

          <div className="form__group">
            <label>필요한 상담 내용 *</label>
            <div className="checkbox__group__grid">
              <div className="checkbox__item">
                <input
                  type="radio"
                  id="policyFunds"
                  name="consult"
                  value="정책자금"
                  onChange={handleChange}
                />
                <label htmlFor="policyFunds">정책자금</label>
              </div>

              <div className="checkbox__item">
                <input
                  type="radio"
                  id="governmentSubsidy"
                  name="consult"
                  value="정부 지원금"
                  onChange={handleChange}
                />
                <label htmlFor="governmentSubsidy">정부 지원금</label>
              </div>

              <div className="checkbox__item">
                <input
                  type="radio"
                  id="certification"
                  name="consult"
                  value="기업인증"
                  onChange={handleChange}
                />
                <label htmlFor="certification">기업인증</label>
              </div>

              <div className="checkbox__item">
                <input
                  type="radio"
                  id="researchFacilities"
                  name="consult"
                  value="기업부설연구소"
                  onChange={handleChange}
                />
                <label htmlFor="researchFacilities">기업부설연구소</label>
              </div>
            </div>
          </div>

          <button type="submit" className="submit__button">
            작성
          </button>
          <button className="close__button" onClick={closeFastModal}>
            닫기
          </button>
        </form>
      </div>
    </div>
  );
}

export default FastConsult;