import React, { useState } from 'react'
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import Logo from '../assets/images/cropped-image.png';
import { useMediaQuery } from "react-responsive"

function Footer({ openModalUsing, openModalPrivate }) {
  const isPc = useMediaQuery({
    query: "(min-width:1024px)"
  });
  const isTablet = useMediaQuery({
    query: "(min-width:768px) and (max-width:1023px)"
  });
  const isMobile = useMediaQuery({
    query: "(max-width:767px)"
  });
  return (
    <div className='footer-container'>
      <div className='social-media'>

        <div className='footer-logo'>
          <Link to='/' className='social-logo'>
            <img className='footer-logo-detail' src={Logo} alt="Logo" />
            {/* <i class='fab fa-typo3' /> */}
          </Link>
        </div>
        {!isMobile && <>
          <div class="footer-content">
            <div className='text-line'>
              <p>대표: 오태우 |</p>
              <p> 사업체명: EM파트너스 |</p>
              <p> 주소: 부산시 남구 진남로 206 (3층, 346호) </p>
            </div>
            <div className='text-line'>
            </div>
            <div className='text-line'>
              <p> 사업자번호: 846-60-00749 |</p>
              <p>전화번호: 1688-7510 | </p>
              <p> 대표메일: empartners77@gmail.com | </p>
              <p> 팩스: 0504-232-2306</p>
            </div>
          </div>
        </>}

        {isMobile && <>
          <div class="footer-content">
            <div className='text-line'>
              <p>대표: 오태우</p>
            </div>
            <div className='text-line'>
              <p> 사업체명: EM파트너스</p>
            </div>
            <div className='text-line'>
              <p> 주소: 부산시 남구 진남로 206 (3층, 346호) </p>
            </div>
            <div className='text-line'>
              <p> 사업자번호: 846-60-00749</p>
            </div>
            <div className='text-line'>
              <p>전화번호: 1688-7510</p>
            </div>
            <div className='text-line'>
              <p> 대표메일: empartners77@gmail.com</p>
            </div>
            <div className='text-line'>
              <p> 팩스: 0504-232-2306</p>
            </div>
          </div>
        </>}
        
        {isPc && <>
          <div className='promise'>
            <div className='point' onClick={() => openModalUsing()}>
              <p>이용약관</p>
            </div>
            <div className='point' onClick={() => openModalPrivate()}>
              <p>개인정보처리방침</p>
            </div>
          </div>
        </>}

        {!isPc && <>
          <div className='promise'>
            <div className='point' onClick={() => openModalUsing()}>
              <p>이용약관</p>
            </div>
            <div className='point' onClick={() => openModalPrivate()}>
              <p>개인정보처리방침</p>
            </div>
          </div>
        </>}

      </div>

      <div className='copyright'>
        <p className='copyright-text'> Copyright &copy; 2024 EMMAPARTNERS. All Rights Reserved.</p>
      </div>
    </div>
  );
}

export default Footer;